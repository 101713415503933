import React from 'react'
import Button from '../Button'

import styles from './ErrorPage.module.scss'

export default function ErrorPage({
  error,
  title,
  info
}) {
  return (
    <div className={[styles.errorPage].join(' ')}>
      <div className={styles.error}>{error}</div>
      <div className={styles.title}>{title}</div>
      <div className={styles.info}>{info}</div>
      <div className={styles.button}>
        <Button title="GO HOME" link="/" />
      </div>
    </div>
  )
}