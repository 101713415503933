import React from 'react'
import ErrorPage from '../components/ErrorPage'
import PageLayout from '../components/PageLayout'

export default function FourOhFour() {
  return (
    <PageLayout>
      <ErrorPage 
        error="404"
        title="Page Not Found"
        info="Oops! The page you're looking for does not seem to exist"
      />
    </PageLayout>
  )
}